import { deleteProps } from "@inceptionbg/ui-components";
import { TFunction } from "i18next";
import { IInputComponentProps } from "../../../../../Elements/Input";
import { IRefactionRequestItemNew } from "../../../../../types/IRefaction";
import {
  getFuelTypeOptions,
  getLandsCultureOptions,
  getLandsOptions,
} from "../../../../../utils/LoadOptions";
import { NumberInput } from "../../../../Inputs/NumberInput";
import { SelectAsyncPaginate } from "../../../../Inputs/SelectAsyncPaginate";

export const getRefactionItemData: (props: {
  formData: IRefactionRequestItemNew;
  setFormData?: (item: IRefactionRequestItemNew) => void;
  t: TFunction;
  edit: boolean;
  farmUuid: string;
  excludeFuelTypeUuids?: string[];
  invoiceUuids?: string[];
  items?: IRefactionRequestItemNew[];
  cultureLiterRecapitulation: number;
  setCultureLiterRecapitulation: (value: number) => void;
  landLiterLeft: { [key: string]: number };
  setLandLiterLeft: (
    value: React.SetStateAction<{ [key: string]: number }>
  ) => void;
  year: string;
}) => IInputComponentProps[] = ({
  formData,
  setFormData,
  t,
  edit,
  farmUuid,
  excludeFuelTypeUuids,
  invoiceUuids,
  items,
  cultureLiterRecapitulation,
  setCultureLiterRecapitulation,
  landLiterLeft,
  setLandLiterLeft,
  year,
}) => {
  const selectedLandId = formData.landObj?.value;
  const landLiterFromMap = formData.landObj?.value
    ? landLiterLeft[selectedLandId] || 0
    : 0;

  let remainFuelLitersToUse = Math.min(
    landLiterFromMap,
    cultureLiterRecapitulation!
  );

  return [
    {
      label: t("Land"),
      value:
        formData.landPlantCulture?.land?.nickname || formData.landObj?.label,
      input: setFormData && (
        <SelectAsyncPaginate
          required
          value={formData.landObj}
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) => {
            const filters = {
              hasAtLeastOnePlantCulture: true,
              approved: true,
              refractionFilter: true,
              excludeLandPlantCultureUuids: "",
              year,
            };

            if (items?.length) {
              filters.excludeLandPlantCultureUuids = JSON.stringify(
                items.map((item) => item.landPlantCulture?.uuid!)
              );
            }

            return getLandsOptions(
              search,
              loadedOptions,
              offset,
              farmUuid,
              ["LITER_RECAPITULATION"],
              filters
            );
          }}
          onChange={(e) => {
            const newLandLiter =
              e.literRecapitulation.leftLiter - (landLiterLeft[e.value] || 0);
            setFormData(
              e
                ? deleteProps({ ...formData, landObj: e }, ["landPlantCulture"])
                : deleteProps(formData, ["landObj", "landPlantCulture"])
            );
            setLandLiterLeft((prev) => ({
              ...prev,
              [e.value]: newLandLiter,
            }));
          }}
        />
      ),
      edit,
    },
    {
      label: t("LandCulturesTitle"),
      value: formData.landPlantCulture?.plantCulture?.name,
      input: setFormData && (
        <SelectAsyncPaginate
          required
          disabled={!formData.landObj?.value}
          value={
            formData.landPlantCulture
              ? {
                  value: formData.landPlantCulture.uuid,
                  label: formData.landPlantCulture.plantCulture?.name,
                }
              : null
          }
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) => {
            const filters = {
              year,
              refractionAllowed: true,
              excludeLandPlantCultureUuids: "",
            };

            if (items?.length) {
              filters.excludeLandPlantCultureUuids = JSON.stringify(
                items.map((item) => item.landPlantCulture?.uuid!)
              );
            }

            return getLandsCultureOptions(
              search,
              loadedOptions,
              offset,
              formData.landObj?.value,
              ["MAX_LITER", "LITER_RECAPITULATION"],
              filters
            );
          }}
          onChange={(e) => {
            setFormData(
              e
                ? {
                    ...formData,
                    landPlantCulture: {
                      uuid: e.value,
                      plantCulture: { name: e.label },
                      maxLiter: e.maxLiter,
                      literRecapitulation: e.literRecapitulation,
                    },
                  }
                : deleteProps(formData, ["landPlantCulture"])
            );
            setCultureLiterRecapitulation(e.literRecapitulation.leftLiter);
          }}
          refresh={[formData.landObj?.value]}
        />
      ),
      edit,
    },
    {
      label: t("FuelType"),
      value: formData.fuelType?.name,
      input: setFormData && (
        <SelectAsyncPaginate
          required
          value={
            formData.fuelType
              ? {
                  value: formData.fuelType.uuid,
                  label: formData.fuelType.name,
                }
              : null
          }
          loadOptions={(
            search: string,
            loadedOptions: [],
            offset: { page: number }
          ) =>
            getFuelTypeOptions(search, loadedOptions, offset, {
              excludeFuelTypeUuids,
              invoiceUuids,
            })
          }
          onChange={(e) =>
            setFormData(
              e
                ? {
                    ...formData,
                    fuelType: { name: e.label, uuid: e.value },
                  }
                : deleteProps(formData, ["fuelType"])
            )
          }
          refresh={[excludeFuelTypeUuids]}
        />
      ),
      edit,
    },
    {
      label: t("Liter"),
      value: `${formData.liter}`,
      input: setFormData && (
        <NumberInput
          required
          placeholder={remainFuelLitersToUse.toString()}
          helperText={t("LiterHelpText", {
            value: remainFuelLitersToUse,
          })}
          value={formData.liter}
          setValue={(e) => {
            setFormData({
              ...formData,
              liter: +e,
            });
          }}
        />
      ),
      edit,
    },
  ];
};
