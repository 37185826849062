import {
  Dispatch,
  FC,
  SetStateAction,
  useContext,
  useEffect,
  useState,
} from "react";
import { IInvoice } from "../../../../../types/IInvoice";
import { getInvoices } from "../../../../../repos/InvoiceRepo";
import { FarmContext } from "../../../../../AppContent";
import { SubSection } from "../../../../SubSection";
import { formatDate } from "@inceptionbg/ui-components";
import { Input } from "../../../../../Elements/Input";
import { Loader } from "../../../../../Elements/Loader";
import { Checkbox } from "../../../../../Elements/Checkbox";
import { Alert } from "../../../../../Elements/Alert/Alert";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";

interface Props {
  invoices: IInvoice[];
  setInvoices: Dispatch<SetStateAction<IInvoice[]>>;
}

export const RefactionInvoices: FC<Props> = ({ invoices, setInvoices }) => {
  const [availableInvoices, setAvailableInvoices] = useState<IInvoice[]>([]);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation();
  const farmUuid = useContext(FarmContext).farm?.uuid;
  const location = useLocation();
  const year = location.pathname.split("-")[1];

  useEffect(() => {
    if (farmUuid) {
      setIsLoading(true);
      getInvoices(farmUuid, ["ITEMS"], {
        approved: true,
        excludeUsedInvoices: true,
        year,
      })
        .then((data) => {
          setAvailableInvoices(data.invoices);
        })
        .catch(() => {})
        .finally(() => setIsLoading(false));
    }
  }, [farmUuid, year]);

  return (
    <Loader isLoading={isLoading}>
      <div className="flex column gap-3">
        {availableInvoices.map((invoice) => {
          const isSelected = !!invoices.find((e) => e.uuid === invoice.uuid);
          return (
            <div key={invoice.uuid} className="flex align-center gap-3">
              <Checkbox
                selected={isSelected}
                setSelected={() =>
                  setInvoices(
                    isSelected
                      ? invoices.filter((e) => e.uuid !== invoice.uuid)
                      : [...invoices, invoice]
                  )
                }
              />
              <SubSection
                title={`${formatDate(invoice?.date)} - ${invoice?.number}`}
                expandable
                className="pt-1"
              >
                {invoice.items.map((item, i) => (
                  <Input
                    key={item.uuid || `${i}. ${item.fuelType?.uuid}`}
                    component={{
                      label: item.fuelType?.name || "",
                      value: `${item.liter || "0"} l`,
                    }}
                  />
                ))}
              </SubSection>
            </div>
          );
        })}
        {!isLoading && !availableInvoices.length && (
          <Alert text={t("NoInvoicesForRefaction")} type="info" />
        )}
      </div>
    </Loader>
  );
};
