import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { Dialog } from "../../../../Elements/Dialog";
import {
  addEditRefactionRequests,
  deleteRefactionRequest,
  getRefractionRequestByUuid,
} from "../../../../repos/RefactionRepo";
import {
  IRefactionRequest,
  IRefactionRequestItemNew,
} from "../../../../types/IRefaction";
import { RefactionItems } from "./Items/RefactionItems";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  refactionReq: IRefactionRequest | null;
  year: number;
  setRefractionRequest?: (refractionRequest: IRefactionRequest) => void;
  reloadRefactions: () => void;
}

export const EditRefactionRequestDialog: FC<Props> = ({
  isOpen,
  onClose,
  refactionReq,
  year,
  setRefractionRequest,
  reloadRefactions,
}) => {
  const [items, setItems] = useState<IRefactionRequestItemNew[]>(
    refactionReq?.items || []
  );
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(false);
  const [isOpenDeleteRefactionDialog, setIsOpenDeleteRefactionDialog] =
    useState(false);

  const { t } = useTranslation();
  const { reloadFarm } = useContext(FarmContext);
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const handleClose = () => {
    setItems([]);
    onClose();
  };

  const onSubmit = () => {
    if (farmUuid) {
      setIsLoading(true);
      addEditRefactionRequests(
        {
          farm: { uuid: farmUuid },
          ...refactionReq,
          items,
        },
        year
      )
        .then((res) => {
          !!res.identifier &&
            getRefractionRequestByUuid(res.identifier).then(
              (res) =>
                setRefractionRequest &&
                setRefractionRequest(res.refractionRequest)
            );
        })
        .then(() => {
          reloadFarm();
          handleClose();
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <>
      <Dialog
        isOpen={isOpen}
        onClose={handleClose}
        title={t("RefactionRequestItemsTitle")}
        desc={t("RefactionRequestItemsDesc")}
        confirmButton={{
          label: t("RefactionRequestAdd"),
          disabled: !items.length || isFormVisible,
          onClick: () => onSubmit(),
          keepOpen: true,
        }}
        deleteButton={{
          label: t("RefactionRequestDelete"),
          onClick: () => setIsOpenDeleteRefactionDialog(true),
        }}
        size="m"
        isLoading={isLoading}
      >
        <RefactionItems
          items={items}
          setItems={setItems}
          invoiceUuids={refactionReq?.invoices?.map((e) => e.uuid) || []}
          onFormVisibilityChange={setIsFormVisible}
        />
      </Dialog>
      <Dialog
        isOpen={isOpenDeleteRefactionDialog}
        onClose={() => setIsOpenDeleteRefactionDialog(false)}
        title={t("RefactionRequestDelete")}
        desc={t("RefactionRequestDeleteDesc")}
        isLoading={isLoading}
        confirmButton={{
          label: t("Yes"),
          onClick: () => {
            deleteRefactionRequest(refactionReq?.uuid!).then(() => {
              onClose();
              reloadRefactions();
            });
          },
        }}
        cancelButton={{
          label: t("No"),
          onClick: () => setIsOpenDeleteRefactionDialog(false),
        }}
      />
    </>
  );
};
