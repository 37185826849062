import { FC, useContext, useState } from "react";
import { useTranslation } from "react-i18next";
import { FarmContext } from "../../../../AppContent";
import { Dialog } from "../../../../Elements/Dialog";
import {
  addEditRefactionRequests,
  getRefractionRequestByUuid,
} from "../../../../repos/RefactionRepo";
import { IDialogTypeItem } from "../../../../types/IBase";
import { IInvoice } from "../../../../types/IInvoice";
import {
  IRefactionRequest,
  IRefactionRequestItemNew,
} from "../../../../types/IRefaction";
import { RefactionInvoices } from "./Invoices/RefactionInvoices";
import { RefactionItems } from "./Items/RefactionItems";
import { deleteEmptyProps, deleteProps } from "@inceptionbg/ui-components";

interface Props {
  isOpen: boolean;
  onClose: () => void;
  year: number;
  setRefractionRequest?: (refractionRequest: IRefactionRequest) => void;
}

type IStepType = "invoices" | "items";

export const AddRefactionRequestDialog: FC<Props> = ({
  isOpen,
  onClose,
  year,
  setRefractionRequest,
}) => {
  const [step, setStep] = useState<IStepType>("invoices");
  const [invoices, setInvoices] = useState<IInvoice[]>([]);
  const [items, setItems] = useState<IRefactionRequestItemNew[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isFormVisible, setIsFormVisible] = useState(true);

  const { t } = useTranslation();
  const { reloadFarm } = useContext(FarmContext);
  const farmUuid = useContext(FarmContext).farm?.uuid;

  const handleClose = () => {
    setStep("invoices");
    setInvoices([]);
    setItems([]);
    onClose();
  };

  const removeLiterRecapitulation = (obj: any) => {
    if (obj && typeof obj === "object") {
      if ("literRecapitulation" in obj) {
        delete obj.literRecapitulation;
      }
      Object.values(obj).forEach((value) => {
        if (value && typeof value === "object") {
          removeLiterRecapitulation(value);
        }
      });
    }
    return obj;
  };

  const typeData: {
    [key in IStepType]: IDialogTypeItem;
  } = {
    invoices: {
      title: t("NewRefactionRequest"),
      desc: t("NewRefactionRequestDesc"),
      element: (
        <RefactionInvoices
          invoices={invoices}
          setInvoices={(e) => {
            setInvoices(e);
            invoices.length > e.length && setItems([]);
          }}
        />
      ),
      confirmBtn: {
        label: t("AddRefactionRequestItems"),
        onFormSubmit: () => setStep("items"),
        disabled: !invoices.length,
        keepOpen: true,
      },
      cancelBtn: {
        onClick: handleClose,
      },
    },
    items: {
      title: t("RefactionRequestItemsTitle"),
      desc: t("RefactionRequestItemsDesc"),
      element: (
        <RefactionItems
          items={items}
          setItems={setItems}
          invoiceUuids={invoices.map((e) => e.uuid)}
          onFormVisibilityChange={setIsFormVisible}
        />
      ),
      confirmBtn: {
        label: t("RefactionRequestAdd"),
        disabled: !items.length || isFormVisible,
        onClick: () => onSubmit(),
        keepOpen: true,
      },
      cancelBtn: { onClick: () => setStep("invoices") },
    },
  };

  const onSubmit = () => {
    if (farmUuid) {
      setIsLoading(true);
      const itemsWithoutLiterRecapitulation = items.map((item) =>
        removeLiterRecapitulation(
          deleteProps(deleteEmptyProps(item), ["literRecapitulation"])
        )
      );

      addEditRefactionRequests(
        {
          farm: { uuid: farmUuid },
          invoices,
          items: itemsWithoutLiterRecapitulation,
        },
        year
      )
        .then((res) => {
          !!res.identifier &&
            getRefractionRequestByUuid(res.identifier).then(
              (res) =>
                setRefractionRequest &&
                setRefractionRequest(res.refractionRequest)
            );
        })
        .then(() => {
          reloadFarm();
          handleClose();
        })
        .catch(() => setIsLoading(false));
    }
  };

  return (
    <Dialog
      isOpen={isOpen}
      onClose={handleClose}
      title={typeData[step].title}
      desc={typeData[step].desc}
      descEl={typeData[step].descEl}
      confirmButton={typeData[step].confirmBtn}
      cancelButton={typeData[step].cancelBtn}
      size="m"
      isLoading={isLoading}
    >
      {typeData[step].element}
    </Dialog>
  );
};
