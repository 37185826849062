import clsx from "clsx";
import { FC, useContext } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { UserContext } from "../Context/UserContext";
import { FarmContext } from "../AppContent";
import { ISidebarLink, ISidebarType } from "../types/IBase";
import { pageNoAccess } from "../utils/PageAccess";

interface Props {
  type: ISidebarType;
}

export const Sidebar: FC<Props> = ({ type }) => {
  const { t } = useTranslation();
  const { pathname, hash } = useLocation();
  const navigate = useNavigate();
  const farm = useContext(FarmContext).farm;
  const { userInfo } = useContext(UserContext);

  const isRenew = pathname.startsWith("/renew");
  const isNew = pathname.startsWith("/new");
  const isEdit = pathname.startsWith("/edit");

  const noAccess = pageNoAccess(farm, userInfo);

  const sidebarLinks: ISidebarLink[] =
    type === "data"
      ? isRenew && farm?.uuid
        ? [
            {
              label: t("ChangeInfoTitle"),
              url: "/edit",
              disabled: noAccess.edit,
              hidden: !isRenew,
              redirect: true,
            },
          ]
        : [
            {
              label: t("BasicInfoTitleOrdered"),
              url: "#basic",
            },
            {
              label: t("MembersTitleOrdered"),
              url: "#members",
              disabled: isNew,
              hidden: farm?.holder.type !== "physicalPerson",
            },
            {
              label: t("AuthorizedPersonsTitleOrdered"),
              url: "#authorizedPersons",
              disabled: isNew,
              hidden: farm?.holder.type !== "legalEntity",
            },
            {
              label: t("LandTitleOrdered"),
              url: "#land",
              disabled: isNew,
            },
            {
              label: t("AnimalsTitleOrdered"),
              url: "#animals",
              disabled: isNew,
            },
            {
              label: t("RenewOrdered"),
              url: "#renew",
              disabled: !userInfo.hasAtLeastOneFarm,
              hidden:
                !isEdit ||
                ["PENDING", "PENDING_APPROVAL"].includes(farm?.status || ""),
            },
          ]
      : [
          {
            label: t("RealLandTitle"),
            url: "/real-lands",
            disabled: noAccess.edit,
          },
          {
            label: t("ChangeInfoTitle"),
            url: "/edit",
            disabled: noAccess.edit,
          },
          {
            label: t("AHRenewalTitleShort"),
            url: "/renew",
            disabled: noAccess.renew,
          },
          {
            label: t("AHRegisterTitleShort"),
            url: "/new",
            disabled: noAccess.new,
          },
          {
            label: t("StatementsAndCertificatesTitle"),
            url: "/statements",
            disabled: noAccess.statements,
          },
          {
            label: t("StatusChangeTitle"),
            url: "/status-change",
            disabled: noAccess.statusChange,
          },
          {
            label: t("RequestForFarmDelete"),
            url: "/delete",
            disabled: noAccess.delete,
          },
          { label: t("CaseUpdate"), url: "/case-update" },
          { label: t("GeneralRequests"), url: "/general-request" },
          { label: t("ComplaintTitle"), url: "/complaint" },
          { label: t("FuelInvoices"), url: "/receipts" },
          { label: t("Refaction2024"), url: "/refaction-2024" },
          { label: t("Refaction2025"), url: "/refaction-2025" },
          { label: t("Pesticides"), url: "/pesticide-procurements" },
        ];

  return (
    <div className="half-width sticky flex column">
      {sidebarLinks.map(
        (e) =>
          !e.hidden &&
          (!e.redirect && type === "data" ? (
            <a
              key={e.label}
              href={e.url}
              className={clsx("sidebar-link", {
                disabled: e.disabled,
                active: hash === e.url,
              })}
            >
              {e.label}
            </a>
          ) : (
            <div
              key={e.label}
              className={clsx("sidebar-link", {
                disabled: e.disabled,
                active: pathname.endsWith(e.url),
              })}
              onClick={() => !e.disabled && navigate(e.url)}
            >
              {e.label}
            </div>
          ))
      )}
    </div>
  );
};
