export const inputPattern = {
  taxId: "^\\d{9}$",
  registrationNumber: "^\\d{8}$",
  personalIdentityNumber: "^\\d{13}$",
  jbkjs: "^\\d{5}$",
  bankAccount: "^\\d{6,18}$",
  phoneNumber: "^\\+381\\d{7,10}$",
  phone: "^+?\\d{7,13}$",
  postalCode: "^\\d{5}$",
  registrationOrPersonalIdentityNumber: "^\\d{8}|\\d{13}$",
  cadastreNumber: "^\\d*\\/?\\d*$",
  maxChar255: "^.{1,255}$",
  year: "^\\d{4}$",
};
