import { axiosInstance } from "../App";
import {
  IFuelInfo,
  IInvoice,
  IInvoiceNew,
  InvoiceFetchOption,
} from "../types/IInvoice";

export const getInvoices = (
  farmUuid: string,
  fetchOptions?: InvoiceFetchOption[],
  filters?: { approved?: boolean; excludeUsedInvoices?: boolean; year?: string }
) =>
  axiosInstance
    .get(`/farm/${farmUuid}/invoices`, {
      params: { fetchOptions, ...filters },
    })
    .then(({ data }) => ({ invoices: data.invoices as IInvoice[] }));

export const addOrEditReceipt = (data: IInvoiceNew) =>
  axiosInstance.post("/invoice", data);

export const getInvoicesFuel = (invoiceUuid: string[]) =>
  axiosInstance
    .get(`/invoices/grouped`, { params: { invoiceUuid } })
    .then(({ data }) => data.groupedItems as IFuelInfo[]);

export const deleteInvoice = (invoiceUuid: string) =>
  axiosInstance.delete(`/invoice/${invoiceUuid}`);
